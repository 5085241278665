
import { Component, Vue } from "vue-property-decorator";
import RadioField from "@/components/field-handlers/radio-field.vue";
import StringField from "@/components/field-handlers/string-field.vue";
import NumberField from "@/components/field-handlers/number-field.vue";
import SelectField from "@/components/field-handlers/select-field.vue";
import CheckboxField from "@/components/field-handlers/checkbox-field.vue";
import SingleCheckboxField from "@/components/field-handlers/single-checkbox-field.vue";
import YearPickerField from "@/components/field-handlers/yearpicker-field.vue";

@Component({
  components: {
    RadioField,
    StringField,
    NumberField,
    SelectField,
    CheckboxField,
    SingleCheckboxField,
    YearPickerField,
  },
})
export default class Survey extends Vue {
  public schema?: any | null = null;
  public result: any = {};
  public savingData = false;
  public loading = true;
  public failed = false;

  get logoURL() {
    return this.schema?.meta["site-logo"] || "/img/nisto-logo.png";
  }

  requiredFieldsPresent(field: any) {
    const result = this.result as any;

    if (field._requires) {
      const checkFields = field._requires.reduce(
        (a: any, requiredKey: any) =>
          Object.keys(requiredKey).reduce((acc: any, item: any) => {
            return (
              result[item] != null &&
              (Array.isArray(result[item])
                ? result[item].indexOf(requiredKey[item]) > -1
                : result[item] == requiredKey[item])
            );
          }, true) && a,
        true
      );
      return checkFields;
    } else {
      return true;
    }
  }

  async getId() {
    const form = document.getElementById("survey-form") as HTMLFormElement;

    for (const item of document.getElementsByClassName("invalid")) {
      item.classList.remove("invalid");
    }

    if (form != null && !form.checkValidity()) {
      const scrollElement = form.querySelector(":invalid");

      if (scrollElement != null) {
        const scrollParent = scrollElement.parentElement;
        if (scrollElement != null && scrollParent != null) {
          const valFocus = scrollParent.attributes.getNamedItem(
            "validation-focus"
          );
          const valMessage = scrollParent.attributes.getNamedItem(
            "validation-element"
          );

          if (valFocus) {
            this.$scrollTo(
              document.getElementById(valFocus.value) as HTMLElement,
              500
            );
          } else {
            this.$scrollTo(scrollParent, 500);
          }

          if (valMessage) {
            (document.getElementById(
              valMessage.value
            ) as HTMLElement).classList.add("invalid");
          } else {
            scrollElement.classList.add("invalid");
          }
          return;
        }
      }
    }

    // Checkbox group validation
    for (const group of document.getElementsByClassName("checkbox-group")) {
      if (
        Array.from(group.getElementsByTagName("input")).filter((x) => x.checked)
          .length === 0 &&
        this.schema.properties.diversityData.required.indexOf(
          group.getElementsByTagName("input")[0].name
        ) > -1
      ) {
        this.$scrollTo(group);
        group.classList.add("invalid");
        return;
      }
    }

    this.savingData = true;
    const saveResponse = await this.$dataService.saveDiversityData(
      this.result,
      this.schema
    );
    this.savingData = false;

    this.$router.push({
      name: "GenerateId",
      params: { id: saveResponse.id },
      query: {
        email: this.$route.query.email,
        schema: this.$route.query.schema,
      },
    });
  }

  async created() {
    try {
      this.schema = await (this.$route.query.schema
        ? this.$dataService.getSchema(Number(this.$route.query.schema))
        : this.$dataService.getLatestSchema());
    } catch (error) {
      this.failed = true;
    } finally {
      this.loading = false;
    }
  }
}
